<template>
  <div class="table">
    <div class="table__header">
      <slot name="header" />
    </div>
    <slot />
    <div class="table__caliente">
      <div>
        Haz click aquí para realizar esta apuesta en
      </div>
      <div>
        <img src="https://az755631.vo.msecnd.net/golstats-bets/caliente_2020.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParlayTipsTable',
};
</script>

<style scoped lang="scss">
.table {
  font-size: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  border: solid 4px #cbee6b;
  padding: 1.5em 1em 0;

  &__header {
    width: 100%;
    border-radius: 7px;
    padding: 0.3em 0.5em 0.3em;
    background-color: #132839;
    color: white;
    text-align: center;
  }

  &__caliente {
    width: 100%;
    max-width: 34.4em;
    border-radius: 12px;
    background-color: #222527;
    //display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3em 1em;
    flex-wrap: wrap;
    display: none;

    @media screen and (max-width: 594px) {
      padding: 1em 1em;
    }

    & > div {
      &:first-child {
        font-family: Roboto-Regular, sans-serif;
        font-size: 1.125em;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        color: white;
      }

      &:last-child {
        width: 7em;
        height: 3.125em;
        margin: 0 1.5em;
      }
    }
  }
}
</style>
